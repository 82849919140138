.header{
    background-color: #000080;
    border-bottom: 1px solid transparent;
    border-bottom-right-radius:10% 30%;
    width: 100%;
    max-height: 100vh;
    z-index: 0;
    position: relative;
    padding-top: 1px;
    

    .container{
        position: relative;
        margin-right: auto;
        margin-left: auto;
        margin-top: 7%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        max-width: 1080px;
        
    }

    .Image{
        width: 500px;
        max-height: 400px;
        z-index: 10;
        margin: auto;
    }
}

@media screen and (max-width: 1024px) {
    .header{
        height: 100vh;
    }
}

@media screen and (max-width: 768px) {
    .header{
        height: 100vh;
    }
}