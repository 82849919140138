body{
    
}

.HowitWorks{ 
background-color: #000080;
max-height: fit-content;
width: 100%;
text-align: center;
margin: auto;
color: white;
padding: 3% 0;
}

    .pattern{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-image: url('./assets/pattern.png');
    // background-position: inherit;
    background-repeat: repeat;
     background-size: auto;
     height: 70vh;
     width: 100%;
     max-height: 100vh;
    }
    
    .pattern2{
        position: absolute;
        background-image: url('./assets/pattern.png');
        background-position: inherit;
        background-repeat: repeat;
        background-size: auto;
        z-index: 1;
        height: 70vh;
        width: 100%;
        max-height: 100vh;
    }

    .features{
        width: 70%;
        margin: auto;
    }

    .container{
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    .Num-Icon{
        width: 60px;
        height: 60px;
    }

    .contact{
         position: relative;
        width: 90%;
        margin-right: auto;
        margin-left: auto;
        padding: 15px 8px;
        max-width: 1080px;
        margin-top: 5%;
    }