@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

// :root {
//   /* Blue */
//   --primary-color: #FF0000;
//   /* Light Green */
//   --success-green: #1ED260;
//   /* Dark Green */
//   --btn-green: #0F7139;
//   /* Gray with opacity */
//   --secondary-color : rgba(132, 131, 131, 0.77);
//   /* main color with opacity */
//   --highlight-color: mix( var(--primary-color),transparent, 40%);
//   --auth-bg: #F6F6F6;
// }
