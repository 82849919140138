.navbar, .navbar2 {
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5rem;
    z-index: 100;
    color: white;

   
    .Navcontainer{
        position: relative;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        padding: 15px 8px;
        justify-content: space-between; 
        align-items: center;
        width: 90%;
        margin: auto;
        max-width: 1080px;
    }


.navlinks{
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: bold;
}

ul {
    display: flex;
    gap: 1rem;
    padding: 0 1rem;
}

li {
    list-style: none;
    padding: 0.5rem;
    height: 47px;
}

}

.navbar{
    background-color: transparent;
    position: absolute;


}

.navbar2{
background-color: #000080;
position: relative;

}